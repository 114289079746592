@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

@layer base {
    body.dark {
        @apply bg-slate-900 font-body px-5 text-white;
    }

    body {
        @apply bg-white text-slate-900 px-5 font-body;
        transition: all linear 0.3s;
    }
    body input {
        @apply text-white;
    }

    body.dark .movie-card,
    body.dark .input-search {
        @apply bg-slate-800 text-white transition-all;
    }

    .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 30px;
        font-size: 22px;
        font-weight: 500;
    }

    .pagination .active {
        @apply text-primary;
    }

    @media (max-width: 639px) {
        .moive-page {
            @apply grid-cols-1;
        }
        .banner-title {
            max-width: 300px;
        }
    }

    :root {
        --swiper-theme-color: #f62682 !important;
        --swiper-pagination-bullet-inactive-color: #aaa !important;
        --swiper-pagination-bullet-inactive-opacity: 1;
    }
    .swiper-button-prev,
    .swiper-button-next {
        position: absolute !important;
        top: 50% !important;
        width: 60px !important;
        height: 60px !important;
        margin-top: -12.5px !important;
        z-index: 10 !important;
        cursor: pointer !important;
        border-radius: 50% !important;
        background: rgb(204, 204, 204, 0.7) !important;
        transition: all 0.3s ease !important;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        font-weight: 700 !important;
    }

    .swiper-button-prev {
        left: 0;
    }

    .swiper-button-next {
        right: 0;
    }

    .swiper-button-prev:hover,
    .swiper-button-next:hover {
        background: rgb(204, 204, 204) !important;
    }
    .movie-list .swiper-pagination {
        display: none;
    }

    @media (max-width: 1023px) {
        .banner .swiper-pagination {
            display: none !important;
        }
    }
    .skeleton {
        background-color: #eee;
        background-image: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
    }
    @keyframes shine {
        to {
            background-position-x: -200%;
        }
    }
    /* Tạo thanh cuộn */
    body::-webkit-scrollbar {
        width: 10px;
    }

    /* Tạo màu nền cho thanh cuộn */
    body::-webkit-scrollbar-track {
        background-color: #eee;
    }

    /* Tạo màu cho thanh cuộn */
    body::-webkit-scrollbar-thumb {
        background-color: #f62682;
        border-radius: 5px;
    }
    button {
        position: relative;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }

    button:before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        transform: skewX(-30deg);
        transition: all 0.3s ease-in-out;
    }

    button:hover:before {
        left: 0;
    }

    button:hover {
        @apply bg-blue-600;
    }
    button:hover div {
        @apply text-blue-600;
    }
    button div {
        transition: all 0.3s ease-in-out;
    }
}

@layer components {
    .page-container {
        max-width: 1200px;
        @apply mx-auto;
    }
    .movie-list .swiper-slide {
        width: 270px;
    }
    .banner .swiper {
        height: 100%;
    }
    .trailer iframe {
        width: 100%;
        height: 100%;
    }
}
